import React from "react"
import Layout from "../components/Layout/Layout"
import HeaderImg from "../components/Components/HeaderImg";
import { Link } from "gatsby";

export default function Kontakt() {
    return (
        <Layout>
            <HeaderImg />
            <div className="container pb-5">
                <h1>Individuální lekce</h1>
                <p>První setkání začneme půl hodinkou, kdy Vy budete povídat, já naslouchat a dělat si poznámky. <br />Dále Vás seznámím s nezbytnou součástí lekcí, terapií BEMER.  </p>
                <p>Více o Bemer terapii naleznete&nbsp;
                    <a href="https://hrstkova.bemergroup.com/cs" target="_blank">zde</a>.</p>
                <p>Poté připravím tréninkový plán, body, na které je potřeba se zaměřit a samozřejmě i vhodně zvolenou relaxaci.</p>
                <p>Obsahem našich setkání budou:
                    <ol type="a">
                        <li>&emsp;konzultace,</li>
                        <li>&emsp;cvičení,</li>
                        <li>&emsp;relaxace,</li>
                        <li>&emsp;BEMER terapie,</li>
                        <li>&emsp;barevná a aroma terapie,</li>
                        <li>&emsp;opakování.  </li>
                    </ol>
                </p>
                <p>Skladba lekcí je vždy stejná: 45 minut cvičíme / 15 minut relaxujeme. Vše sestaveno na míru a v průběhu laděno dle možností a potřeb.</p>

                <h2>Cena:</h2>
                <p>Cena jednotlivých individuálních lekcí:	 600,- Kč.</p>
                <p>Lze zakoupit balíček 4 lekcí (s platností 2 měsíce) za 2200,- Kč = 1 lekce vychází pouze na 550,- Kč. </p>
                <p>Úvodní poznávací lekce je zdarma a trvá 30 minut. Osobně se seznámíme, řekneme si, co vy od cvičení očekáváte a já mohu nabídnout. Po zpracování informací se můžeme domluvit na další spolupráci.</p>


            </div>

        </Layout>
    );
}